"use client";

import { ErrorPage } from "@tw-components/error/ErrorPage";
export default function Error({
  error,
  reset
}: {
  error: Error & {
    digest?: string;
  };
  reset: () => void;
}) {
  return <ErrorPage error={error} data-sentry-element="ErrorPage" data-sentry-component="Error" data-sentry-source-file="error.tsx" />;
}