import { useGetUserSession } from "@custom-hooks/user";
import { CircleBrandMark } from "@layouts/ApplicationLayout/CircleBrandMark";
import { DecorationBottomLeft, DecorationBottomRight, DecorationTopLeft, DecorationTopRight } from "@layouts/svg-illustration/sing-in-decoration";
import * as Sentry from "@sentry/nextjs";
import Link from "next/link";
import { useEffect } from "react";

/**
 * Props for the ErrorPage component.
 *
 * @property {number} [statusCode] - The HTTP status code associated with the error.
 * @property {any} [error] - The error object to capture and display.
 * @property {() => void} [onNavigateToHomeClicked] -
 */
type ErrorPageProps = {
  statusCode?: number;
  error?: any;
  onNavigateToHomeClicked?: () => void;
};

/**
 * This component is used to display a customized error page when an error occurs
 * on the application. It provides an error message and sends the error details
 * to Sentry for monitoring.
 *
 * @param {ErrorPageProps} props - The properties passed to the ErrorPage component.
 *
 * @returns {JSX.Element} The rendered error page.
 */
export function ErrorPage({
  statusCode,
  error,
  onNavigateToHomeClicked
}: ErrorPageProps) {
  const {
    data: session,
    isLoading: isLoadingSession
  } = useGetUserSession();
  useEffect(() => {
    if (!isLoadingSession && error) {
      Sentry.captureException(error, {
        user: {
          email: session?.user?.email,
          id: session?.user?.id
        }
      });
    }
  }, [session, error, isLoadingSession]);
  return <div className="tw-relative tw-h-screen tw-overflow-hidden" data-sentry-component="ErrorPage" data-sentry-source-file="ErrorPage.tsx">
      <div className="tw-absolute tw-left-6 tw-top-6 tw-z-[51]">
        <CircleBrandMark data-sentry-element="CircleBrandMark" data-sentry-source-file="ErrorPage.tsx" />
      </div>
      <div className="tw-relative tw-z-50 tw-flex tw-h-full tw-items-center tw-justify-center tw-overflow-auto tw-p-6">
        <div className="tw-max-w-[22.875rem]">
          <section>
            <div className="tw-text-center tw-font-sans tw-text-h4-desk">
              Something went wrong!
            </div>
            <div className="tw-mt-3 tw-text-center tw-font-sans tw-text-14px-light">
              {error ? `${error}` : statusCode ? `Error: ${statusCode}` : "An unexpected error occurred."}
            </div>
            <div className="tw-mt-8 tw-flex tw-items-center tw-justify-center">
              <div aria-hidden="true" className="tw-h-px tw-w-full tw-bg-lightGrey" data-orientation="horizontal" role="separator"></div>
              <Link href="/" onClick={onNavigateToHomeClicked} data-sentry-element="Link" data-sentry-source-file="ErrorPage.tsx">
                <div className="tw-text-14px-med tw-mx-2 tw-text-nowrap tw-px-2 tw-py-1 tw-text-mediumblue focus-visible:tw-rounded-custom-xl focus-visible:tw-border-0 focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-black">
                  Back to home page
                </div>
              </Link>
              <div aria-hidden="true" className="tw-h-px tw-w-full tw-bg-lightGrey" data-orientation="horizontal" role="separator"></div>
            </div>
          </section>
        </div>
      </div>
      <DecorationTopLeft data-sentry-element="DecorationTopLeft" data-sentry-source-file="ErrorPage.tsx" />
      <DecorationBottomLeft data-sentry-element="DecorationBottomLeft" data-sentry-source-file="ErrorPage.tsx" />
      <DecorationTopRight data-sentry-element="DecorationTopRight" data-sentry-source-file="ErrorPage.tsx" />
      <DecorationBottomRight data-sentry-element="DecorationBottomRight" data-sentry-source-file="ErrorPage.tsx" />
    </div>;
}