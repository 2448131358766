//
// sing-in-decoration.tsx
//

//react
import React from "react";

//
// types
//
interface DecorationProps {}

//
// decorations
//

const DecorationTopLeft: React.FC<DecorationProps> = () => {
  //render
  return <div className="tw-pointer-events-none tw-absolute tw-left-0 tw-top-0 tw-h-[auto] tw-w-[15vw]" data-sentry-component="DecorationTopLeft" data-sentry-source-file="sing-in-decoration.tsx">
      <svg width="100%" viewBox="0 0 237 920" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="sing-in-decoration.tsx">
        <path opacity="0.18" fillRule="evenodd" clipRule="evenodd" d="M-194.994 -265.197C-17.5664 -205.485 200.519 -120.457 233.658 63.5844C266.371 245.256 37.2363 351.143 -55.2711 510.757C-135.218 648.698 -126.994 838.328 -263.341 921.116C-421.38 1017.07 -642.542 1064.15 -795.869 960.433C-944.411 859.955 -898.883 638.221 -939.509 463.735C-973.033 319.754 -1064.07 180.222 -1009.49 42.9426C-952.219 -101.103 -805.836 -182.527 -660.735 -237.422C-509.594 -294.601 -348.285 -316.787 -194.994 -265.197Z" fill="#C3D7F2" data-sentry-element="path" data-sentry-source-file="sing-in-decoration.tsx" />
      </svg>
    </div>;
};
const DecorationBottomLeft: React.FC<DecorationProps> = () => {
  //render
  return <div className="tw-pointer-events-none tw-absolute tw-bottom-0 tw-left-0 tw-h-[100vh] tw-w-auto" data-sentry-component="DecorationBottomLeft" data-sentry-source-file="sing-in-decoration.tsx">
      <svg height="100%" viewBox="0 0 374 920" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="sing-in-decoration.tsx">
        <path opacity="0.18" fillRule="evenodd" clipRule="evenodd" d="M-155.068 -118.188C-354.4 -187.152 -607.422 -261.534 -763.747 -120.063C-918.06 19.5872 -786.697 272.03 -814.165 478.283C-837.904 656.53 -975.863 820.162 -911.32 988.037C-836.509 1182.62 -671.742 1377.06 -463.588 1390.13C-261.93 1402.79 -149.782 1173.46 6.63687 1045.68C135.709 940.235 312.999 878.403 358.898 718.227C407.059 550.156 332.628 376.582 241.067 227.576C145.693 72.3678 17.1483 -58.6058 -155.068 -118.188Z" fill="#C3D7F2" data-sentry-element="path" data-sentry-source-file="sing-in-decoration.tsx" />
      </svg>
    </div>;
};
const DecorationTopRight: React.FC<DecorationProps> = () => {
  //render
  return <div className="tw-pointer-events-none tw-absolute tw-right-0 tw-top-0 tw-h-[auto] tw-w-[28vw]" data-sentry-component="DecorationTopRight" data-sentry-source-file="sing-in-decoration.tsx">
      <svg width="100%" viewBox="0 0 435 920" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="sing-in-decoration.tsx">
        <path opacity="0.18" fillRule="evenodd" clipRule="evenodd" d="M1307.4 804.024C1191.47 980.227 1036.28 1193.46 826.112 1176.65C618.653 1160.06 568.843 879.875 421.31 733.149C293.809 606.346 84.6887 560.773 33.4828 388.361C-25.8694 188.52 -13.8368 -66.0602 143.176 -203.342C295.288 -336.339 524.014 -222.95 725.905 -217.048C892.499 -212.177 1070.72 -271.272 1204.77 -172.304C1345.42 -68.4586 1392.26 114.5 1410.55 288.43C1429.59 469.601 1407.57 651.79 1307.4 804.024Z" fill="#C3D7F2" data-sentry-element="path" data-sentry-source-file="sing-in-decoration.tsx" />
      </svg>
    </div>;
};
const DecorationBottomRight: React.FC<DecorationProps> = () => {
  //render
  return <div className="tw-pointer-events-none tw-absolute tw-bottom-0 tw-right-0 tw-h-[auto] tw-w-[24vw]" data-sentry-component="DecorationBottomRight" data-sentry-source-file="sing-in-decoration.tsx">
      <svg width="100%" viewBox="0 0 346 525" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="sing-in-decoration.tsx">
        <path opacity="0.18" fillRule="evenodd" clipRule="evenodd" d="M861.885 9.64444C1069.71 45.6459 1327.16 102.861 1395.61 302.278C1463.17 499.127 1226.13 656.597 1150.46 850.423C1085.07 1017.93 1126.72 1227.87 989.062 1343.62C829.502 1477.78 591.265 1568.33 402.735 1479.13C220.089 1392.72 232.794 1137.75 157.648 950.272C95.6405 795.571 -29.6592 655.732 7.60152 493.329C46.6989 322.922 195.77 206.965 347.958 120.798C506.482 31.0433 682.327 -21.4596 861.885 9.64444Z" fill="#C3D7F2" data-sentry-element="path" data-sentry-source-file="sing-in-decoration.tsx" />
      </svg>
    </div>;
};
export { DecorationBottomLeft, DecorationBottomRight, DecorationTopLeft, DecorationTopRight };